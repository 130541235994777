import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { faFaceParty, faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  BootstrapDateTimeInput,
  FormCard,
  TypeaheadSingleInput,
} from '../components/forms';
import ResponsiveIconSubmitButton from '../components/ResponsiveIconSubmitButton';
import axios from 'axios';
import { apiUrl } from '../utils/url';
import { getJwt } from '../utils/jwt';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { getFormattedDateTimeString } from '../utils/dateTimeFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertSchema = Yup.object().shape({
  message: Yup.string()
    .max(100, 'Message exceeds max character count')
    .required('Message is required'),
  title: Yup.string()
    .max(48, 'Title exceeds max character count')
    .required('Title is required'),
  link: Yup.string()
    .url('Link should be a valid URL')
    .required('Link is required'),
  segmentUuid: Yup.string().required('Segment is required'),
  schedule: Yup.date('Schedule must be a date').required(
    'Schedule is required',
  ),
});

function TotdMarketingPush() {
  const [alreadySent, setAlreadySent] = useState(false);
  const { data: segments = [] } = useAllQuery('bsft-segments');

  const handleSubmit = async (values, { setSubmitting }) => {
    const toastId = toast.loading('Sending marketing push...');

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJwt()}`,
      },
    };

    const pushName = `${getFormattedDateTimeString(
      false,
      new Date(values.schedule),
    )}_TOTD_APP_MARKETING_PUSH`;

    const CONTENT = {
      APNS: {
        aps: {
          alert: {
            title: values.title,
            body: values.message,
          },
          'interruption-level': 'active',
        },
        web_url: values.link,
      },
      GCM: {
        notification: {
          title: values.title,
          body: values.message,
        },
        data: {
          web_url: values.link,
        },
      },
    };

    try {
      const { data: pushTemplateData } = await axios.post(
        `${apiUrl}blueshift/templates/push`,
        {
          name: pushName,
          content: CONTENT,
          tag_data: 'All_Tags:Native Push,TOTD App,Dedicated',
        },
        axiosConfig,
      );

      try {
        const { data: campaignData } = await axios.post(
          `${apiUrl}blueshift/campaigns/one-time`,
          {
            send_to_unsubscribed: false,
            triggers: [
              {
                trigger_name: 'Native Push',
                template_uuid: pushTemplateData.uuid,
                utm_campaign: pushName,
                utm_source: 'TOTD_APP',
                target_platform: 'all',
              },
            ],
            name: pushName,
            launch: !values.draftMode,
            startdate: new Date(values.schedule).toISOString(),
            segment_uuid: values.segmentUuid,
            tag_data: 'All_Tags:Native Push,TOTD App,Dedicated',
          },
          axiosConfig,
        );

        console.log(campaignData);
        toast.update(toastId, {
          render: 'Sent marketing push!',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        setAlreadySent(true);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: 'Could not create one-time send campaign...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: 'Could not create push template...',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
      setSubmitting(false);
    }
  };

  if (alreadySent) {
    return (
      <Container fluid className="text-center">
        <Row>
          <Col>
            <h2>TOTD Marketing Push</h2>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <FontAwesomeIcon
              icon={faFaceParty}
              size="9x"
              className="text-cpblue-500"
              spin
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Message sent! Please refresh page to send a new message.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="TOTD Marketing Push"
      >
        <Formik
          initialValues={{
            message: '',
            title: '',
            link: '',
            segmentUuid: '',
            schedule: '',
            draftMode: false,
          }}
          validationSchema={AlertSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => (
            <Form>
              <Row>
                <BootstrapTextInput
                  required
                  fullWidth
                  maxLength={48}
                  label="Title"
                  id="title"
                  name="title"
                  placeholder="Title"
                />
                <BootstrapTextInput
                  required
                  fullWidth
                  isTextArea
                  maxLength={100}
                  label="Message"
                  id="message"
                  name="message"
                  placeholder="Message"
                />
                <BootstrapTextInput
                  required
                  fullWidth
                  label="Link"
                  id="link"
                  name="link"
                  placeholder="Link"
                />
                <TypeaheadSingleInput
                  required
                  fullWidth
                  label="Segment"
                  id="segmentUuid"
                  name="segmentUuid"
                  placeholder="Segment"
                  options={segments}
                  labelKey="name"
                  valueKey="uuid"
                  objects
                  renderMenuItemChildren={(option) => (
                    <div>
                      {option.name}
                      <div>
                        <small>Push Users: {option.push_users}</small>
                      </div>
                    </div>
                  )}
                />
                <BootstrapDateTimeInput
                  required
                  fullWidth
                  label="Schedule"
                  id="schedule"
                  name="schedule"
                />
                <BootstrapToggleInput
                  fullWidth
                  label="Draft Mode"
                  id="draftMode"
                  name="draftMode"
                  labelChecked="This message will be put in draft mode"
                  labelUnchecked="This message will go live at the scheduled time"
                />
              </Row>
              <ResponsiveIconSubmitButton
                disabled={isSubmitting || !isValid}
                icon={faPaperPlane}
              >
                Send
              </ResponsiveIconSubmitButton>
            </Form>
          )}
        </Formik>
      </FormCard>
    </Container>
  );
}

export default TotdMarketingPush;
