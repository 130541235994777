export const _USER = {
  username: '',
  firstName: '',
  lastName: '',
  profileImage: '',
  roles: [],
  broadcasts: [],
  userGroups: [],
};

export const _ROLE = {
  name: '',
  modifiedBy: '',
  modifiedAt: '',
  permissions: [],
  users: [],
};

export const _USER_GROUP = {
  name: '',
  modifiedBy: '',
  modifiedAt: '',
  users: [],
  broacastTemplates: [],
};

export const _TEMPLATE_FILE = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  type: '',
  content: '',
  broacastTemplates: [],
};

export const _TAG = {
  name: '',
};

export const _INPUT_FIELD = {
  name: '',
  tags: [],
  type: '',
  modifiedBy: '',
  modifiedAt: '',
  options: [],
  broacastTemplates: [],
  required: false,
};

export const _PLACEHOLDER_MAPPING = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  key: '',
  value: '',
};

export const _BSFT_EMAIL_CONFIG = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  broacastTemplates: [],
  adapterUuid: '',
  segmentUuid: '',
  userCount: 0,
  userCountLastUpdated: '',
  fromName: '',
  utmSource: '',
};

export const _BSFT_SMS_CONFIG = {
  name: '',
  tags: [],
  fromName: '',
  modifiedBy: '',
  modifiedAt: '',
  broacastTemplates: [],
  adapterUuid: '',
  segmentUuid: '',
  userCount: 0,
  userCountLastUpdated: '',
  rebrandlyDomain: '',
};

export const _WP_CONFIG = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  broacastTemplates: [],
  site: '',
  authorId: '',
  categoryIds: [],
  pubCodeIds: [],
  portfolio: '',
};

export const _CONTENTFUL_CONFIG = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  site: '',
  broacastTemplates: [],
  authorId: '',
  categoryId: '',
  publicationId: '',
  portfolio: '',
  pro: false,
  lifetime: false,
};

export const _BROADCAST_TEMPLATE = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  defaultContent: '',
  defaultOptions: [],
  itemCode: '',
  emailEnabled: false,
  smsEnabled: false,
  webEnabled: false,
  webDelay: false,
  keywordsEnabled: false,
  testEnabled: false,
  inputFields: [],
  emailBroadcasts: [],
  emailTemplate: null,
  smsBroadcasts: [],
  webPosts: [],
  userGroups: [],
  broadcasts: [],
  externalFetchTemplates: [],
};

export const _BROADCAST = {
  name: '',
  tags: [],
  modifiedBy: '',
  modifiedAt: '',
  createdBy: '',
  createdAt: '',
  broadcastTemplate: '',
  options: [],
  broadcastContent: {},
  status: {},
};

export const _WORDPRESS_BROADCAST_OPTIONS = [
  { key: 'wordpress-objectives', value: '' },
  { key: 'wordpress-psychologies', value: '' },
  { key: 'wordpress-sectors', value: '' },
  { key: 'wordpress-featured-image', value: '' },
];
