import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { faFaceParty, faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  BootstrapDateTimeInput,
  FormCard,
  TypeaheadSingleInput,
  ImageUploadInputFormik,
} from '../components/forms';
import ResponsiveIconSubmitButton from '../components/ResponsiveIconSubmitButton';
import axios from 'axios';
import { apiUrl } from '../utils/url';
import { getJwt } from '../utils/jwt';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { getFormattedDateTimeString } from '../utils/dateTimeFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertSchema = Yup.object().shape({
  message: Yup.string()
    .max(1600, 'Message exceeds max character count')
    .required('Message is required'),
  subject: Yup.string().required('Subject is required'),
  mediaUrl: Yup.string()
    .url('MMS Image URL should be a valid URL')
    .required('MMS Image URL is required'),
  segmentUuid: Yup.string().required('Segment is required'),
  schedule: Yup.date('Schedule must be a date').required(
    'Schedule is required',
  ),
});

function TdWatchMarketingMMS() {
  const [alreadySent, setAlreadySent] = useState(false);
  const { data: segments = [] } = useAllQuery('bsft-segments');

  const handleSubmit = async (values, { setSubmitting }) => {
    const toastId = toast.loading('Sending marketing mms...');

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJwt()}`,
      },
    };

    const smsName = `${getFormattedDateTimeString(
      false,
      new Date(values.schedule),
    )}_TDWATCH_MARKETING_MMS`;

    try {
      const { data: smsTemplateData } = await axios.post(
        `${apiUrl}blueshift/templates/sms`,
        {
          template: {
            resource: {
              content: values.message,
              shorten_links: false,
              media_url: values.mediaUrl,
              subject: values.subject,
            },
            name: smsName,
            tag_data: 'All_Tags:TDWATCH,SMS,Dedicated',
          },
        },
        axiosConfig,
      );

      try {
        const { data: campaignData } = await axios.post(
          `${apiUrl}blueshift/campaigns/one-time`,
          {
            send_to_unsubscribed: false,
            triggers: [
              {
                trigger_name: 'MMS',
                template_uuid: smsTemplateData.uuid,
                utm_campaign: smsName,
                utm_source: 'TDWATCH_MMS',
                account_adapter_uuid: '539afe76-fa95-4d88-abba-b36bae1e5e6e',
              },
            ],
            name: smsName,
            launch: !values.draftMode,
            startdate: new Date(values.schedule).toISOString(),
            segment_uuid: values.segmentUuid,
            tag_data: 'All_Tags:TDWATCH,SMS,Dedicated',
          },
          axiosConfig,
        );

        console.log(campaignData);
        toast.update(toastId, {
          render: 'Sent marketing MMS!',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        setAlreadySent(true);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: 'Could not create one-time send campaign...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: 'Could not create mms template...',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
      setSubmitting(false);
    }
  };

  if (alreadySent) {
    return (
      <Container fluid className="text-center">
        <Row>
          <Col>
            <h2>TDWATCH Marketing MMS</h2>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <FontAwesomeIcon
              icon={faFaceParty}
              size="9x"
              className="text-cpblue-500"
              spin
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Message sent! Please refresh page to send a new message.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="TDWATCH Marketing MMS"
      >
        <Formik
          initialValues={{
            message:
              'YOUR MESSAGE HERE.\n\nText STOP WATCH to stop or STOP to cancel all.',
            subject: 'TD+ Alerts: ',
            mediaUrl: '',
            segmentUuid: '523da035-de5a-4873-8d59-395098a9f276',
            schedule: '',
            draftMode: false,
          }}
          validationSchema={AlertSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => (
            <Form>
              <Row>
                <BootstrapTextInput
                  required
                  fullWidth
                  label="Subject"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                />
                <BootstrapTextInput
                  required
                  fullWidth
                  isTextArea
                  maxLength={1600}
                  label="Message"
                  id="message"
                  name="message"
                  placeholder="Message"
                />
                <ImageUploadInputFormik
                  required
                  fullWidth
                  label="MMS Image"
                  id="mediaUrl"
                  name="mediaUrl"
                  placeholder="MMS Image"
                />
                <TypeaheadSingleInput
                  required
                  fullWidth
                  label="Segment"
                  id="segmentUuid"
                  name="segmentUuid"
                  placeholder="Segment"
                  options={segments}
                  labelKey="name"
                  valueKey="uuid"
                  objects
                  renderMenuItemChildren={(option) => (
                    <div>
                      {option.name}
                      <div>
                        <small>MMS Users: {option.sms_users}</small>
                      </div>
                    </div>
                  )}
                />
                <BootstrapDateTimeInput
                  required
                  fullWidth
                  label="Schedule"
                  id="schedule"
                  name="schedule"
                />
                <BootstrapToggleInput
                  fullWidth
                  label="Draft Mode"
                  id="draftMode"
                  name="draftMode"
                  labelChecked="This message will be put in draft mode"
                  labelUnchecked="This message will go live at the scheduled time"
                />
              </Row>
              <ResponsiveIconSubmitButton
                disabled={isSubmitting || !isValid}
                icon={faPaperPlane}
              >
                Send
              </ResponsiveIconSubmitButton>
            </Form>
          )}
        </Formik>
      </FormCard>
    </Container>
  );
}

export default TdWatchMarketingMMS;
