import React, { useEffect } from 'react';
import { Col, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { useField } from 'formik';

function BootstrapDateTimeInput({
  label,
  value = null,
  fullWidth = false,
  required = false,
  ...props
}) {
  const [field, meta, { setValue }] = useField(props);

  useEffect(() => {
    if (value) {
      setValue(value, true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Input
          type="datetime-local"
          invalid={meta.touched && meta.error ? true : false}
          {...field}
          {...props}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default BootstrapDateTimeInput;
