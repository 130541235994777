import axios from 'axios';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  _BROADCAST,
  _BROADCAST_TEMPLATE,
  _BSFT_EMAIL_CONFIG,
  _BSFT_SMS_CONFIG,
  _INPUT_FIELD,
  _ROLE,
  _TAG,
  _TEMPLATE_FILE,
  _USER,
  _USER_GROUP,
  _WP_CONFIG,
  _PLACEHOLDER_MAPPING,
  _CONTENTFUL_CONFIG,
} from '../../../empties';
import { getJwt } from '../../../jwt';
import { apiUrl } from '../../../url';

const EMPTIES = {
  broadcasts: _BROADCAST,
  'broadcast-templates': _BROADCAST_TEMPLATE,
  'bsft-email-configs': _BSFT_EMAIL_CONFIG,
  'bsft-sms-configs': _BSFT_SMS_CONFIG,
  'input-fields': _INPUT_FIELD,
  roles: _ROLE,
  tags: _TAG,
  'template-files': _TEMPLATE_FILE,
  users: _USER,
  'user-groups': _USER_GROUP,
  'wp-configs': _WP_CONFIG,
  'placeholder-mappings': _PLACEHOLDER_MAPPING,
  'contentful-configs': _CONTENTFUL_CONFIG,
};

const URLS = {
  'bsft-segments': 'blueshift/segments',
  'bsft-email-adapters': 'blueshift/adapters',
  'bsft-sms-adapters': 'blueshift/adapters',
  'bsft-external-fetch-templates': 'blueshift/external-fetch-templates',
  'wp-authors': 'wordpress/authors',
  'wp-categories': 'wordpress/terms',
  'wp-pubcodes': 'wordpress/terms',
  'wp-global-taxonomies': 'wordpress/global-taxonomies',
  'contentful-authors': 'contentful/authors',
  'contentful-article-categories': 'contentful/article-categories',
  'contentful-publications': 'contentful/publications',
  'decision-vision-list-codes': 'decision-vision/list-codes',
  'broadcast-launched-dates': 'broadcasts/stats/launched-dates',
  'broadcast-created-dates': 'broadcasts/stats/created-dates',
  'broadcast-launched-campaign-counts':
    'broadcasts/stats/launched-campaign-counts',
  'rebrandly-domains': 'rebrandly/domains',
};

const QUERY_STRINGS = {
  broadcasts: 'populate=modifiedBy',
  'broadcast-templates': 'populate=modifiedBy',
  'bsft-email-configs': 'populate=modifiedBy',
  'bsft-sms-configs': 'populate=modifiedBy',
  'input-fields': 'populate=modifiedBy',
  roles: 'populate=modifiedBy',
  tags: '',
  'template-files': 'populate=modifiedBy',
  users: '',
  'user-groups': 'populate=modifiedBy',
  'bsft-segments': '',
  'bsft-email-adapters': 'channel=Email',
  'bsft-sms-adapters': 'channel=SMS',
  'bsft-external-fetch-templates': '',
  'wp-configs': 'populate=modifiedBy',
  'contentful-authors': 'sites=oxfordclub',
  'contentful-article-categories': 'sites=oxfordclub',
  'contentful-publications': 'sites=oxfordclub',
  'contentful-configs': 'populate=modifiedBy',
  'wp-authors': 'sites=oxfordclub,manwardpress,mtatradeoftheday',
  'wp-categories':
    'type=category&sites=oxfordclub,manwardpress,mtatradeoftheday',
  'wp-pubcodes': 'type=pubcode&sites=oxfordclub,manwardpress,mtatradeoftheday',
  'wp-global-taxonomies': '',
  'placeholder-mappings': 'populate=modifiedBy',
  'decision-vision-list-codes': '',
};

// GET BY ID
const getById = async (key, id, populate, version) => {
  if (id !== 'new') {
    try {
      const queryParams = [];
      if (populate) queryParams.push(`populate=${populate}`);
      if (version) queryParams.push(`version=${version}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

      const options = {
        method: 'GET',
        url: `${apiUrl}${URLS[key] || key}/${id}${queryString}`,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      };
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error; // Rethrow or handle as needed
    }
  }

  return EMPTIES[key];
};

// SINLGE QUERY
const useSingleQuery = (key, id, populate = null, version = null) => {
  const queryKey = [key, id];
  if (version) {
    queryKey.push(version);
  }
  return useQuery(queryKey, () => getById(`${key}s`, id, populate, version));
};

// GET PAGINATED
const getPaginated = async (key, params) => {
  let queryString = '';
  const paramKeys = Object.keys(params);
  paramKeys.forEach((key, index) => {
    if (index > 0) {
      queryString += `&${key}=${params[key]}`;
    } else {
      queryString += `?${key}=${params[key]}`;
    }
  });
  const options = {
    method: 'GET',
    url: `${apiUrl}${URLS[key] || key}/search/${queryString}`,
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  };
  const { data } = await axios.request(options);
  return data;
};

// USE PAGINATION
const usePagination = (key, params) => {
  const queryClient = useQueryClient();
  const {
    data = { docs: [] },
    isLoading,
    isError,
    error,
  } = useQuery([key, params], () => getPaginated(key, params));

  useEffect(() => {
    if (data && data.hasNextPage) {
      queryClient.prefetchQuery([key, { ...params, page: data.nextPage }], () =>
        getPaginated(key, { ...params, page: data.nextPage }),
      );
    }
  }, [queryClient, data, params, key]);

  return { data, isLoading, isError, error };
};

// GET ALL
const getAll = async (key) => {
  const options = {
    method: 'GET',
    url: `${apiUrl}${URLS[key] || key}/?${QUERY_STRINGS[key]}`,
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  };
  const { data } = await axios.request(options);
  return data;
};

// ALL QUERY
const useAllQuery = (key) => {
  return useQuery(key, () => getAll(key));
};

// PREFETCH HOOK
const usePrefetchAll = (key) => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(key, () => getAll(key));
};

export { useSingleQuery, useAllQuery, usePagination, usePrefetchAll };
