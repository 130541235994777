import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../../utils/url';
import { getJwt } from '../../utils/jwt';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import TestEmailModal from '../../components/TestEmailModal';
import {
  _BROADCAST,
  _BROADCAST_TEMPLATE,
  _WORDPRESS_BROADCAST_OPTIONS,
} from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import LaunchBroadcastModal from '../../components/LaunchBroadcastModal';
import {
  ContentAndOptions,
  Email,
  WebPost,
  Sms,
  ReviewAndLaunch,
} from './broadcastFlow';
import { FormCard } from '../../components/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faForward,
  faRocketLaunch,
} from '@fortawesome/pro-duotone-svg-icons';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { capitalizeWords } from '../../utils/strings';
import TestSmsModal from '../../components/TestSmsModal';

function Broadcast() {
  const { id } = useParams();
  const { data, isLoading, isError, error, status } = useSingleQuery(
    'broadcast',
    id,
    'broadcastTemplate,inputFields,emailTemplate,emailBroadcasts,webPosts.doc,smsBroadcasts.config,smsBroadcasts.template',
  );
  const { update } = useItemMutation('broadcast');
  const [broadcastState, setBroadcastState] = useState(_BROADCAST);
  const [broadcastTemplate, setBroadcastTemplate] =
    useState(_BROADCAST_TEMPLATE);
  const { data: tags = [] } = useAllQuery('tags');
  const { add: addTag } = useItemMutation('tag', false);
  const [disabled, setDisabled] = useState(false);
  const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
  const [testSmsModalOpen, setTestSmsModalOpen] = useState(false);
  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  // Modifier To Force Editor Render
  const [keyModifier, setKeyModifier] = useState(0);
  // Keep track of what step we're on
  const [step, setStep] = useState(0);
  // Keep a list of steps available
  const [steps, setSteps] = useState([]);
  // Is the options form valid?
  const [optionsValid, setOptionsValid] = useState(true);

  // Set initial values on success status
  useEffect(() => {
    if (status === 'success') {
      if (data) {
        // Grab our broadcast template object to make it easier to work with
        // Let's clean up the web posts now
        const updatedTemplate = { ...data.broadcastTemplate };
        updatedTemplate.webPosts = updatedTemplate.webPosts.map(
          (webPost) => webPost.doc,
        );
        setBroadcastTemplate(updatedTemplate);
        // Let's loop through our default options and see which ones have been set already
        const initOptions = data.broadcastTemplate.defaultOptions.map(
          (defaultOption) =>
            data.options.filter(
              (option) => option.key === defaultOption.key,
            )[0] || defaultOption,
        );
        // Now let's add any options that don't have defaults
        data.options.forEach((option) => {
          const exists =
            initOptions.filter((iOption) => iOption.key === option.key)[0] ||
            null;
          if (!exists) {
            initOptions.push(option);
          }
        });
        // Let's see if we have web posts and if the options exist yet
        if (data.broadcastTemplate.webEnabled) {
          _WORDPRESS_BROADCAST_OPTIONS.forEach((option) => {
            const exists =
              initOptions.filter((iOption) => iOption.key === option.key)[0] ||
              null;
            if (!exists) {
              initOptions.push(option);
            }
          });
        }
        setBroadcastState({ ...data, options: [...initOptions] });
        setKeyModifier(1);
      }
    }
  }, [status, data, isLoading]);

  // Build our list of steps
  useEffect(() => {
    const newSteps = ['contentAndOptions'];
    if (broadcastTemplate) {
      if (broadcastTemplate.emailEnabled) {
        newSteps.push('email');
      }
      if (broadcastTemplate.webEnabled) {
        newSteps.push('webPost');
      }
      if (broadcastTemplate.smsEnabled) {
        newSteps.push('sms');
      }
    }
    newSteps.push('reviewAndLaunch');
    setSteps(newSteps);
  }, [broadcastTemplate]);

  const handleStepChange = async (action) => {
    if (action === 'next') {
      // capture success
      let success = true;
      // Check to see what step we are leaving
      switch (steps[step]) {
        case 'contentAndOptions':
          break;
        case 'email':
          // Do we have a template?
          if (!broadcastState.status.hasOwnProperty('bsftEmailTemplate')) {
            // We need to create a template
            success = await handleTestEmail([]);
          }
          break;
        case 'webPost':
          // Do we have web posts?
          if (!broadcastState.status.hasOwnProperty('webPosts')) {
            // We need to create draft posts
            success = await handleTestWeb();
          }
          break;
        case 'sms':
          // Do we have sms templates?
          if (!broadcastState.status.hasOwnProperty('bsftSmsTemplates')) {
            // We need to create sms templates
            success = await handleTestSms();
          }
          break;
        default:
          break;
      }
      if (step < steps.length - 1 && success) {
        setStep(step + 1);
      }
    } else if (action === 'prev') {
      if (step > 0) {
        setStep(step - 1);
      }
    }
  };

  const handleTestEmail = async (emails, personalizeFor) => {
    setDisabled(true);
    const toastId = toast.loading(
      emails.length > 0
        ? 'Sending test email...'
        : 'Creating email template...',
    );
    let success = true;
    // First we need to update the broadcast
    try {
      await update.mutateAsync({
        ...broadcastState,
        broadcastTemplate: broadcastTemplate._id,
      });
      console.log('Successfully Updated!');
      // Now we can send the test!
      const { data } = await axios.post(
        `${apiUrl}broadcasts/test-email/${broadcastState._id}`,
        {
          recipients: emails,
          personalizeFor,
        },
        {
          headers: {
            Authorization: `Bearer ${getJwt()}`,
          },
        },
      );
      setBroadcastState(data);
      success =
        emails.length > 0
          ? data.status.sendEmailTest &&
            data.status.sendEmailTest.status === 'ok'
          : data.status.bsftEmailTemplate?.uuid;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      toast.update(toastId, {
        render: success
          ? emails.length > 0
            ? 'Email test sent successfully!'
            : 'Email template created successfully!'
          : emails.length > 0
          ? 'Error sending email test'
          : 'Error creating email template',
        type: success ? 'success' : 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setDisabled(false);
      return success;
    }
  };

  const handleTestSms = async (mobileNumber = null) => {
    setDisabled(true);
    const toastId = toast.loading(
      mobileNumber ? 'Sending test sms...' : 'Creating sms templates...',
    );
    let success = true;
    // First we need to update the broadcast
    try {
      await update.mutateAsync({
        ...broadcastState,
        broadcastTemplate: broadcastTemplate._id,
      });
      console.log('Successfully Updated!');
      // Now we can send the test!
      const { data } = await axios.post(
        `${apiUrl}broadcasts/test-sms/${broadcastState._id}`,
        {
          mobileNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${getJwt()}`,
          },
        },
      );
      setBroadcastState(data);
      success = mobileNumber
        ? data.status.sendSmsTest && data.status.sendSmsTest.status === 'ok'
        : data.status.bsftSmsTemplates &&
          Object.keys(data.status.bsftSmsTemplates).length > 0;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      toast.update(toastId, {
        render: success
          ? mobileNumber
            ? 'SMS test sent successfully!'
            : 'SMS templates created successfully!'
          : mobileNumber
          ? 'Error sending sms test'
          : 'Error creating sms templates',
        type: success ? 'success' : 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setDisabled(false);
      return success;
    }
  };

  const handleTestWeb = async () => {
    setDisabled(true);
    const toastId = toast.loading('Creating web post drafts...');
    let success = true;
    // First we need to update the broadcast
    try {
      await update.mutateAsync({
        ...broadcastState,
        broadcastTemplate: broadcastTemplate._id,
      });
      console.log('Successfully Updated!');
      // Now we can send the test!
      const { data } = await axios.post(
        `${apiUrl}broadcasts/test-web-post/${broadcastState._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getJwt()}`,
          },
        },
      );
      setBroadcastState(data);
      success =
        data.status.webPosts && Object.keys(data.status.webPosts).length > 0;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      toast.update(toastId, {
        render: success
          ? 'Web post draft created!'
          : 'Error creating web post draft',
        type: success ? 'success' : 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setDisabled(false);
      return success;
    }
  };

  const handleLaunch = async (data) => {
    setDisabled(true);
    const toastId = toast.loading('Launching broadcast...');
    const broadcast = broadcastState;
    update.mutate(
      {
        ...broadcast,
        broadcastTemplate: broadcastTemplate._id,
      },
      {
        onSuccess: () => {
          console.log('Successfully Updated!');
          // Now we can launch the broadcast!
          axios
            .post(`${apiUrl}broadcasts/launch/${broadcast._id}`, data, {
              headers: {
                Authorization: `Bearer ${getJwt()}`,
              },
            })
            .then(({ data }) => {
              toast.update(toastId, {
                render: 'Launched broadcast!',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
              });
              setBroadcastState(data);
            })
            .catch((error) => {
              toast.update(toastId, {
                render: 'Error launching broadcast...',
                type: 'error',
                isLoading: false,
                autoClose: 1500,
              });
              console.error(error);
            });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save broadcast... Launch canceled.',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setDisabled(false);
        },
      },
    );
  };

  const handleSubmit = () => {
    setDisabled(true);
    const toastId = toast.loading('Saving broadcast...');

    // We are only ever updating a broadcast due to the nature in which it is created
    const broadcast = broadcastState;
    update.mutate(
      {
        ...broadcast,
        broadcastTemplate: broadcastTemplate._id,
      },
      {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved broadcast!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setDisabled(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save broadcast...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setDisabled(false);
        },
      },
    );
  };

  const renderNameAndTags = () => (
    <Row>
      <Col>
        <FormGroup>
          <Label for="name">
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
            Name
          </Label>
          <Input
            id="name"
            name="name"
            placeholder="Name"
            type="text"
            value={broadcastState.name}
            onChange={(e) => {
              e.persist();
              setBroadcastState((old) => ({
                ...old,
                name: e.target.value,
              }));
            }}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label for="tags">
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
            Tags
          </Label>
          <Typeahead
            id="tags"
            name="tags"
            placeholder="Tags"
            multiple
            labelKey="name"
            options={tags}
            onChange={(selected) =>
              setBroadcastState((old) => ({
                ...old,
                tags: selected.map((tag) => {
                  if (tag.hasOwnProperty('customOption')) {
                    // This is a new tag
                    addTag.mutate(
                      { name: tag.name },
                      {
                        onSuccess: () => {
                          console.log('Added New Tag!');
                        },
                        onError: (error) => {
                          console.error(error);
                        },
                      },
                    );
                  }
                  return tag.name;
                }),
              }))
            }
            selected={tags.filter((tag) =>
              broadcastState.tags.includes(tag.name),
            )}
            allowNew={true}
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const renderSteps = () => {
    switch (steps[step]) {
      case 'contentAndOptions':
        return (
          <ContentAndOptions
            externalKey={keyModifier}
            renderNameAndTags={renderNameAndTags}
            initialHtml={
              broadcastState.broadcastContent.initialHtml ||
              broadcastTemplate.defaultContent ||
              ''
            }
            setHtml={(html) => {
              if (html !== broadcastState.broadcastContent.initialHtml) {
                setBroadcastState((old) => ({
                  ...old,
                  broadcastContent: {
                    ...old.broadcastContent,
                    initialHtml: html,
                    webPostHtml: null,
                    emailContentHtml: null,
                    emailAssembledHtml: null,
                    smsAssembledContent: null,
                  },
                }));
              }
            }}
            inputFields={broadcastTemplate.inputFields}
            options={broadcastState.options}
            setOptions={(newOptions) =>
              setBroadcastState((old) => ({
                ...old,
                options: newOptions,
              }))
            }
            setIsValid={setOptionsValid}
            wordPress={
              broadcastTemplate.webEnabled
                ? broadcastTemplate.webPosts.filter(
                    (config) => config.type === 'WpConfig',
                  )[0].site || null
                : false
            }
          />
        );
      case 'webPost':
        return (
          <WebPost
            externalKey={keyModifier}
            initialHtml={
              broadcastState.broadcastContent.webPostHtml ||
              broadcastState.broadcastContent.initialHtml ||
              ''
            }
            setHtml={(html) =>
              setBroadcastState((old) => ({
                ...old,
                broadcastContent: {
                  ...old.broadcastContent,
                  webPostHtml: html,
                },
              }))
            }
            testWebPost={handleTestWeb}
            webPostStatus={broadcastState.status?.webPosts || null}
            launched={broadcastState.status?.launched || false}
          />
        );
      case 'email':
        return (
          <Email
            externalKey={keyModifier}
            itemCode={broadcastTemplate.itemCode}
            keywordsEnabled={broadcastTemplate.keywordsEnabled}
            emailTemplate={broadcastTemplate.emailTemplate.content}
            options={broadcastState.options}
            initialHtml={
              broadcastState.broadcastContent.emailContentHtml ||
              broadcastState.broadcastContent.initialHtml ||
              ''
            }
            setContentHtml={(html) =>
              setBroadcastState((old) => ({
                ...old,
                broadcastContent: {
                  ...old.broadcastContent,
                  emailContentHtml: html,
                },
              }))
            }
            setAssembledHtml={(html) => {
              setBroadcastState((old) => ({
                ...old,
                broadcastContent: {
                  ...old.broadcastContent,
                  emailAssembledHtml: html,
                },
              }));
            }}
            sendEmailTest={() => setTestEmailModalOpen(true)}
            emailTemplateStatus={
              broadcastState.status?.bsftEmailTemplate || null
            }
            launched={broadcastState.status?.launched || false}
          />
        );
      case 'sms':
        return (
          <Sms
            setIsValid={setOptionsValid}
            setSmsContent={(content) => {
              setBroadcastState((old) => ({
                ...old,
                broadcastContent: {
                  ...old.broadcastContent,
                  smsAssembledContent: content,
                },
              }));
            }}
            smsContent={
              broadcastState.broadcastContent.smsAssembledContent || {}
            }
            smsBroadcasts={broadcastTemplate.smsBroadcasts || null}
            itemCode={broadcastTemplate.itemCode}
            options={broadcastState.options}
            status={broadcastState.status}
            sendSmsTest={() => setTestSmsModalOpen(true)}
            launched={broadcastState.status?.launched || false}
            webPostConfigs={
              broadcastTemplate.webEnabled ? broadcastTemplate.webPosts : []
            }
          />
        );
      case 'reviewAndLaunch':
        return <ReviewAndLaunch status={broadcastState.status} />;
      default:
        return <div />;
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          useCard={true}
          breadcrumbLink="/dashboard/broadcasts"
          breadcrumbLabel="Broadcasts"
          breadcrumbItem={
            broadcastState.name.length > 0
              ? broadcastState.name
              : 'New Broadcast'
          }
          numSteps={steps.length}
          progressTitle={
            steps[step] === 'sms'
              ? 'SMS'
              : capitalizeWords(steps[step] || 'loading')
          }
          progressValue={step + 1}
          extraInline={
            <span className="float-end">
              <span className="text-bg-cpblue-500 rounded py-2 px-3 ms-2">
                {data.broadcastTemplate.name.toUpperCase()}
              </span>
              <span
                className={`${
                  broadcastState.status?.testEnabled
                    ? 'text-bg-cpyellow-500'
                    : 'text-bg-cpred-500'
                } rounded py-2 px-3 ms-2`}
              >
                {broadcastState.status?.testEnabled ? 'TEST' : 'LIVE'}
              </span>
              <span
                className={`${
                  broadcastState.status?.launched
                    ? 'text-bg-cpred-300'
                    : 'text-bg-cpblue-300'
                } rounded py-2 px-3 ms-2`}
              >
                {broadcastState.status?.launched ? 'LAUNCHED' : 'IN PROGRESS'}
              </span>
            </span>
          }
        >
          <Row className="mb-3">
            <Col>{renderSteps()}</Col>
          </Row>
          <Row>
            <Col>
              <Button
                disabled={!optionsValid || disabled}
                className={(step < 1 && 'd-none') || 'float-start'}
                onClick={() => handleStepChange('prev')}
                color="cpblue-400"
              >
                <FontAwesomeIcon icon={faBackward} />
                <span className="ms-2">Back</span>
              </Button>
            </Col>
            <Col>
              <Button
                block
                disabled={
                  !optionsValid || disabled || broadcastState.status?.launched
                }
                onClick={handleSubmit}
                color="cpblue-500"
              >
                Save
              </Button>
            </Col>
            <Col>
              {/* Next Button will be hidden on final page */}
              <Button
                className={
                  (step === steps.length - 1 && 'd-none') || 'float-end'
                }
                disabled={!optionsValid || disabled}
                onClick={() => handleStepChange('next')}
                color="cpblue-400"
              >
                <FontAwesomeIcon icon={faForward} />
                <span className="ms-2">Next</span>
              </Button>
              {/* Launch Button will be shown on final page */}
              <Button
                className={
                  (step === steps.length - 1 && 'float-end') || 'd-none'
                }
                disabled={
                  !optionsValid || disabled || broadcastState.status?.launched
                }
                onClick={() => setLaunchModalOpen(true)}
                color="cpred-500"
              >
                <FontAwesomeIcon icon={faRocketLaunch} />
                <span className="ms-2">Launch</span>
              </Button>
            </Col>
          </Row>
        </FormCard>
      )}
      <TestEmailModal
        open={testEmailModalOpen}
        defaultEmail={
          broadcastTemplate.emailBroadcasts[0]
            ? broadcastTemplate.emailBroadcasts[0].testEmail || ''
            : ''
        }
        onClose={(emails, personalizeFor, action) => {
          if (action === 'send') {
            if (emails.length > 0) {
              handleTestEmail(emails, personalizeFor);
              setTestEmailModalOpen(false);
            } else {
              toast.info('Please select at least one email...');
            }
          } else {
            setTestEmailModalOpen(false);
          }
        }}
      />
      <TestSmsModal
        open={testSmsModalOpen}
        onClose={(mobileNumber, action) => {
          if (action === 'send') {
            if (mobileNumber) {
              handleTestSms(`1${mobileNumber}`);
              setTestSmsModalOpen(false);
            } else {
              toast.info('Please enter a valid phone number...');
            }
          } else {
            setTestSmsModalOpen(false);
          }
        }}
      />
      <LaunchBroadcastModal
        open={launchModalOpen}
        emailEnabled={broadcastTemplate.emailEnabled}
        smsEnabled={broadcastTemplate.smsEnabled}
        webEnabled={broadcastTemplate.webEnabled}
        onClose={(data, action) => {
          if (action === 'send') {
            handleLaunch(data);
            setLaunchModalOpen(false);
          } else {
            setLaunchModalOpen(false);
          }
        }}
      />
    </Container>
  );
}

export default Broadcast;
