import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  SaveButton,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _CONTENTFUL_CONFIG } from '../../utils/empties';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

const ContentfulConfigSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  site: Yup.string().required('Site is required'),
  authorId: Yup.string().required('Author is required'),
  categoryId: Yup.string().required('Category is required'),
  publicationId: Yup.string().required('Publication is required'),
  portfolio: Yup.string(),
  pro: Yup.boolean(),
  lifetime: Yup.boolean(),
});

function ContentfulConfig() {
  const { id } = useParams();
  const {
    data = _CONTENTFUL_CONFIG,
    isLoading,
    isError,
    error,
  } = useSingleQuery('contentful-config', id);
  const { add, update } = useItemMutation('contentful-config');
  const { data: authors = [] } = useAllQuery('contentful-authors');
  const { data: categories = [] } = useAllQuery(
    'contentful-article-categories',
  );
  const { data: publications = [] } = useAllQuery('contentful-publications');

  const renderSiteSpecifics = (values) => {
    if (values.site.length > 0) {
      return (
        <>
          <Row>
            <TypeaheadSingleInput
              label="Author"
              id="authorId"
              name="authorId"
              placeholder="Author"
              options={
                authors.hasOwnProperty(values.site) ? authors[values.site] : []
              }
              objects
              labelKey="displayName"
              valueKey="_id"
            />
            <TypeaheadSingleInput
              label="Article Category"
              id="categoryId"
              name="categoryId"
              placeholder="Article Category"
              options={
                categories.hasOwnProperty(values.site)
                  ? categories[values.site]
                  : []
              }
              objects
              labelKey="title"
              valueKey="_id"
              renderMenuItemChildren={(option) => (
                <div>
                  {option.title}
                  <div>
                    <small>Slug: {option.slug}</small>
                  </div>
                </div>
              )}
            />
            <TypeaheadSingleInput
              label="Publication"
              id="publicationId"
              name="publicationId"
              placeholder="Publication"
              options={
                publications.hasOwnProperty(values.site)
                  ? publications[values.site]
                  : []
              }
              objects
              labelKey="itemCode"
              valueKey="_id"
              renderMenuItemChildren={(option) => (
                <div>
                  {option.itemCode}
                  <div>
                    <small>
                      Type: {option.type} | Slug: {option.slug}
                    </small>
                  </div>
                </div>
              )}
            />
          </Row>
        </>
      );
    } else {
      return <div />;
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving web config...');
    // If there is a category and/or publication, let's set the hidden slug fields
    // Keep in mind that we need to search in the site specific arrays
    if (values.categoryId) {
      const category = categories[values.site].find(
        (category) => category._id === values.categoryId,
      );
      values.categorySlug = category.slug;
    }
    if (values.publicationId) {
      const publication = publications[values.site].find(
        (publication) => publication._id === values.publicationId,
      );
      values.publicationSlug = publication.slug;
    }

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/contentful-configs"
          breadcrumbLabel="Contentful Configs"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New Contentful Config'
          }
        >
          <Formik
            initialValues={data}
            validationSchema={ContentfulConfigSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <TypeaheadSingleInput
                    fullWidth
                    label="Site"
                    id="site"
                    name="site"
                    placeholder="Site"
                    options={['oxfordclub']}
                  />
                </Row>
                <Row>
                  {renderSiteSpecifics(values)}
                  <BootstrapTextInput
                    label="Portfolio"
                    id="portfolio"
                    name="portfolio"
                    placeholder="Portfolio"
                  />
                  <BootstrapToggleInput
                    fullWidth
                    label="Lifetime"
                    id="lifetime"
                    name="lifetime"
                    labelChecked="Requires Lifetime Membership."
                    labelUnchecked="Does NOT Require Lifetime Membership."
                  />
                  <BootstrapToggleInput
                    label="Pro"
                    id="pro"
                    name="pro"
                    labelChecked="Requires Pro Membership."
                    labelUnchecked="Does NOT Require Pro Membership."
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default ContentfulConfig;
