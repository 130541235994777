import React, { useState } from 'react';
import {
  Container,
  Nav,
  NavItem,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import { useLocation, Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faUsersRectangle,
  faUserLock,
  faFiles,
  faPenField,
  faMessageSms,
  faMailbox,
  faMemoCircleInfo,
  faTowerBroadcast,
  faCropSimple,
  faChartNetwork,
  faMobileSignal,
  faCommentsDollar,
  faSpaghettiMonsterFlying,
  faHammer,
  faToolbox,
  faFolderGear,
  faCircleR,
  faC,
} from '@fortawesome/pro-duotone-svg-icons';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { baseUrl } from '../utils/url';
import { usePrefetchAll } from '../utils/hooks/reactQuery/queries';
import UserAvatar from '../components/UserAvatar';
import { useUser } from '../utils/contexts/userContext';
import {
  faChevronDown,
  faChevronUp,
  faSignOut,
  faUser,
} from '@fortawesome/pro-light-svg-icons';

function DashboardLayout({ handleLogout }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  // NAV DROPDOWNS
  const [navDropdownsOpen, setNavDropdownsOpen] = useState({
    utils: false,
    admin: false,
  });
  const toggleNavDropdown = (navDropdown) => {
    const newNavDropdownsOpen = { ...navDropdownsOpen };
    Object.keys(newNavDropdownsOpen).forEach((key) => {
      if (key !== navDropdown) {
        newNavDropdownsOpen[key] = false;
      } else {
        newNavDropdownsOpen[key] = !newNavDropdownsOpen[key];
      }
    });
    setNavDropdownsOpen(newNavDropdownsOpen);
  };

  const { user } = useUser();
  const location = useLocation();
  // PREFETCH
  usePrefetchAll('broadcasts');
  usePrefetchAll('broadcast-templates');
  usePrefetchAll('bsft-email-configs');
  usePrefetchAll('bsft-sms-configs');
  usePrefetchAll('input-fields');
  usePrefetchAll('tags');
  usePrefetchAll('template-files');
  usePrefetchAll('roles');
  usePrefetchAll('users');
  usePrefetchAll('user-groups');
  usePrefetchAll('wp-configs');
  usePrefetchAll('contentful-configs');
  usePrefetchAll('placeholder-mappings');
  usePrefetchAll('broadcast-launched-dates');
  usePrefetchAll('broadcast-created-dates');
  usePrefetchAll('broadcast-launched-campaign-counts');
  usePrefetchAll('rebrandly-domains');

  const PAGES = [
    {
      path: 'broadcasts',
      title: 'Broadcasts',
      icon: faTowerBroadcast,
    },
    {
      path: 'admin',
      title: 'Administration',
      icon: faFolderGear,
      pages: [
        {
          path: 'broadcast-templates',
          title: 'Broadcast Templates',
          icon: faMemoCircleInfo,
        },
        {
          path: 'template-files',
          title: 'Template Files',
          icon: faFiles,
        },
        {
          path: 'input-fields',
          title: 'Input Fields',
          icon: faPenField,
        },
        {
          path: 'bsft-email-configs',
          title: 'Blueshift Email Configs',
          icon: faMailbox,
        },
        {
          path: 'bsft-sms-configs',
          title: 'Blueshift SMS Configs',
          icon: faMessageSms,
        },
        {
          path: 'wp-configs',
          title: 'WordPress Configs',
          icon: faWordpress,
        },
        {
          path: 'contentful-configs',
          title: 'Contentful Configs',
          icon: faC,
        },
        {
          path: 'users',
          title: 'Users',
          icon: faUsers,
        },
        {
          path: 'user-groups',
          title: 'User Groups',
          icon: faUsersRectangle,
        },
        {
          path: 'roles',
          title: 'Roles',
          icon: faUserLock,
        },
        {
          path: 'placeholder-mappings',
          title: 'Placeholder Mappings',
          icon: faChartNetwork,
        },
      ],
    },
    {
      path: 'utils',
      title: 'Utilities',
      icon: faToolbox,
      pages: [
        {
          path: 'hero-crop',
          title: 'Hero Crop',
          icon: faCropSimple,
        },
        {
          path: 'table-builder',
          title: 'Table Builder',
          icon: faHammer,
        },
        {
          path: 'totd-marketing-push',
          title: 'TOTD Marketing Push',
          icon: faMobileSignal,
        },
        {
          path: 'tdwatch-marketing-mms',
          title: 'TDWATCH Marketing MMS',
          icon: faCommentsDollar,
        },
        {
          path: 'pasta-docs',
          title: 'Pasta Docs',
          icon: faSpaghettiMonsterFlying,
        },
        {
          path: 'rebrandly-links',
          title: 'Rebrandly Links',
          icon: faCircleR,
        },
      ],
    },
  ];

  const popNav = (pages) => {
    return pages.map((page, index) => {
      if (page.pages) {
        // let's grab all of our page's paths
        const pagePaths = page.pages.map((page) => page.path);
        // if any of our page's paths are in our current location, we want to mark the dropdown as active
        const hasActivePage = pagePaths.some((path) =>
          location.pathname.includes(path),
        );
        return (
          <React.Fragment key={`${page.path}-${index}`}>
            <NavItem
              className={`text-start my-2 nav-link ${
                hasActivePage && !navDropdownsOpen[page.path]
                  ? 'text-bg-cpblue-500 active'
                  : 'text-cpblue-200'
              }`}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleNavDropdown(page.path)}
            >
              <FontAwesomeIcon icon={page.icon} size="lg" />
              <span className="ms-2 d-none d-sm-inline">{page.title}</span>
              <span className="float-end">
                <FontAwesomeIcon
                  icon={
                    navDropdownsOpen[page.path] ? faChevronUp : faChevronDown
                  }
                />
              </span>
            </NavItem>
            {navDropdownsOpen[page.path] && (
              <div className="ps-2 border-start border-3 border-cpblue-400">
                {popNav(page.pages)}
              </div>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <NavItem key={`${page.path}-${index}`} className="text-start my-2">
            <Link
              className={`nav-link ${
                location.pathname.includes(page.path)
                  ? 'text-bg-cpblue-500 active'
                  : 'text-cpblue-200'
              }`}
              to={page.path}
            >
              <FontAwesomeIcon icon={page.icon} size="lg" />
              <span className="ms-2 d-none d-sm-inline">{page.title}</span>
            </Link>
          </NavItem>
        );
      }
    });
  };

  return (
    <Container fluid>
      <Row className="vh-100">
        <Col className="col-auto shadow" style={{ minWidth: '265px' }}>
          <Link to="/dashboard">
            <img
              className="my-3 mx-auto d-block"
              src={`${baseUrl}cp-logo-700x120-light.png`}
              width="175px"
              alt="Copy Pasta"
            />
          </Link>
          <hr />
          <Nav vertical pills>
            {popNav(PAGES)}
          </Nav>
        </Col>
        <Col className="h-100" style={{ overflow: 'scroll' }}>
          <Row className="sticky-top shadow">
            <Navbar color="cpblue-500" container="fluid">
              <Col className="d-flex justify-content-end align-items-center">
                <NavbarBrand tag={'div'} className={user ? 'py-1 px-3' : 'p-3'}>
                  {user && (
                    <Dropdown
                      isOpen={isOpen}
                      toggle={toggleDropdown}
                      direction="down"
                    >
                      <DropdownToggle tag="div" style={{ cursor: 'pointer' }}>
                        <span className="me-3 fs-6 fw-light">{`${user.firstName} ${user.lastName}`}</span>
                        <span className="me-4">
                          <FontAwesomeIcon icon={faChevronDown} size="sm" />
                        </span>
                        <UserAvatar user={user} size={42} />
                      </DropdownToggle>
                      <DropdownMenu
                        end
                        modifiers={[
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 15],
                            },
                          },
                        ]}
                      >
                        <Link
                          to={`users/${user._id}`}
                          className="text-decoration-none"
                        >
                          <DropdownItem>
                            <FontAwesomeIcon icon={faUser} />
                            <span className="ms-3 fs-6 fw-light">Profile</span>
                          </DropdownItem>
                        </Link>
                        <DropdownItem divider />
                        <DropdownItem onClick={handleLogout}>
                          <FontAwesomeIcon icon={faSignOut} />
                          <span className="ms-3 fs-6 fw-light">Log Out</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </NavbarBrand>
              </Col>
            </Navbar>
          </Row>
          <Row>
            <Col className="py-5">
              <Outlet />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardLayout;
